import { USBColumn, USBGrid } from '@usb-shield/react-grid'
import {
  defaultColLayoutOptions,
  defaultGridOptions,
} from '@/utils/usb-grid.util'

import { IconBlockWrapperData } from './iconBlockWrapper.model'
import TextBlockWithIcon from '@/components/TextBlockWithIcon/TextBlockWithIcon'
import styles from './iconBlockWrapper.module.scss'

let gridSettings = defaultGridOptions
const fullWidthColumn = {
  ...defaultColLayoutOptions,
  offset: {
    xlarge: 2,
    large: 0,
    medium: 0,
    small: 0,
  },
  spans: {
    xlarge: 12,
    large: 16,
    medium: 8,
    small: 4,
  },
}
const IconBlockWrapper = ({
  title,
  shortDescription,
  textBlockWithIconParts,
  levelDownHeads,
}: IconBlockWrapperData) => {
  const renderTextBlocks = textBlockWithIconParts.map(function (value: any) {
    return (
      <TextBlockWithIcon
        key={`${value.ctaText}-${value.ctaLink}`}
        title={value.title}
        shortDescription={value.shortDescription}
        ctaText={value.ctaText}
        ctaLink={value.ctaLink}
        linkAsButton={value.linkAsButton}
        iconImage={process.env.CONTENT_DOMAIN + value.iconImage}
        displayView={value.displayView}
        levelDownHeads={levelDownHeads}
        levelUpHeads={!title}
      />
    )
  })

  return (
    <div className={styles.blockWrapperContainer}>
      <USBGrid
        gridGap={defaultGridOptions.gridGap}
        alignItems={defaultGridOptions.alignItems}
        columnCount={defaultGridOptions.columnCount}
        justifyContent={defaultGridOptions.justifyContent}
        display={defaultGridOptions.display}
        padding={defaultGridOptions.padding}
        addClasses={styles.gridWrapper}
      >
        <USBColumn layoutOpts={fullWidthColumn} addClasses={styles.fullWidth}>
          {title?.trim() &&
            (levelDownHeads ? (
              <h3 className={styles.headH3} data-testid="title">
                {title}
              </h3>
            ) : (
              <h2
                className={
                  shortDescription ? `${styles.headH2}` : `${styles.headingH2}`
                }
                data-testid="title"
              >
                {title}
              </h2>
            ))}
          {shortDescription && (
            <p
              className={styles.paragraph}
              dangerouslySetInnerHTML={{
                __html: shortDescription,
              }}
            ></p>
          )}
        </USBColumn>
        <USBColumn layoutOpts={fullWidthColumn} addClasses={styles.fullWidth}>
          <USBGrid
            addClasses={styles.TwelveColumnSubGridFix}
            gridGap={gridSettings.gridGap}
            alignItems={gridSettings.alignItems}
            columnCount="12"
            justifyContent={gridSettings.justifyContent}
            display={gridSettings.display}
            padding={'zero'}
            type={gridSettings.type}
          >
            {renderTextBlocks}
          </USBGrid>
        </USBColumn>
      </USBGrid>
    </div>
  )
}

export default IconBlockWrapper
